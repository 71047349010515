<template>
  <div class="element materialShadow gallery ThemeSection" id="gallery"
       :class="{ displaymode_smallcards: showSmallCards, displaymode_insta: showInsta }">
    <div v-if="isLoading" id='contentLoader' style='margin-top:0;'><icon
      name='fa-cog fa-spin'/>{{ x('loadingDots') }}
    </div>
    <report-and-download-buttons v-show="!isLoading && galleryItems.length > 0"
                                 v-if="config.features[featureNames.downloads] ||
                                 config.features[featureNames.reports]"
    />
    <gallery-drawer v-show="!isLoading && isShowingDrawer" class="right-drawer"
      v-if="config.features[featureNames.downloads] || config.features[featureNames.reports]"/>
    <div v-show="!isLoading" :class="{ 'list-full-width': !isShowingDrawer,
                                       'list-contracted' : isShowingDrawer }"
    id="galleryListContainer">
      <progressive-list-with-columns :items="galleryItems" containerId="galleryListContainer"
                                     :columnWidth="columnWidth"
                                     :noMoreElementsText="'noVisitsFound'"
                                     ref="galleryList">
        <template v-slot="itemData">
          <component :is="itemComponent" :model="itemData.item"/>
        </template>
      </progressive-list-with-columns>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import FeedItem from '@/components/Pages/FeedItem';
  import FeedItemInsta from '@/components/Pages/FeedItemInsta';
  import Events from '@/enums/event-names';
  import Views from '@/enums/views';
  import DisplayModes from '@/enums/display-modes';
  import FeatureNames from '@/enums/feature-names';
  import ReportAndDownloadButtons from '@/components/Gallery/ReportAndDownloadButtons';
  import GalleryDrawer from '@/components/Gallery/GalleryDrawer';
  import ProgressiveListWithColumns from '../ProgressiveListWithColumns';
  import translate from '../Mixins/Translate';

  export default {
    name: 'gallery',
    mixins: [translate],
    components: {
      ReportAndDownloadButtons,
      FeedItem,
      FeedItemInsta,
      ProgressiveListWithColumns,
      GalleryDrawer,
    },
    data() {
      return {
        featureNames: FeatureNames,
      };
    },
    computed: {
      columnWidth() {
        switch (this.displayModes[Views.Gallery]) {
          case DisplayModes.LargeCards: return 200;
          case DisplayModes.SmallCards: return 140;
          case DisplayModes.Insta: return 100;
          default: return 280;
        }
      },
      itemComponent() {
        if (this.displayModes[Views.Gallery] === DisplayModes.Insta) {
          return 'feed-item-insta';
        }
        return 'feed-item';
      },
      showSmallCards() {
        return this.displayModes[Views.Gallery] === DisplayModes.SmallCards;
      },
      showInsta() {
        return this.displayModes[Views.Gallery] === DisplayModes.Insta;
      },
      isShowingDrawer() {
        return this.downloadUIVisible || this.reportUIVisible;
      },
      ...mapState('DownloadStore', ['isLoading', 'downloadUIVisible']),
      ...mapState(['displayModes', 'config', 'galleryItems']),
      ...mapState('ReportStore', ['reportUIVisible']),
    },
    watch: {
      async downloadUIVisible() {
        // We need to reach into the progressive list and trigger a redistribution, because
        // the cell width needs to be recalculated when the available width changes.
        // And this is normally only done when screen width changes.
        await this.$nextTick();
        this.$refs.galleryList.redistributeItems();
      },
      async reportUIVisible() {
        await this.$nextTick();
        this.$refs.galleryList.redistributeItems();
      },
    },
    async mounted() {
      await this.loadGallery();
    },
    methods: {
      async loadGallery() {
        console.log('load gallery');
        // The actual loading of content is done via the filter, which is activated here.
        this.$bus.emit(Events.resetFilter);
      },
    },
  };
</script>

<style lang="scss">
  #gallery {
    height: 100%;

    #galleryListContainer {
      height: calc(100% - 50px);
      overflow-y: scroll;
    }

    .right-drawer {
      height: calc(100% - 50px);
      width: 370px;
      float: right;
      position: relative;
    }
  }

  .list-full-width {
    width: 100%;
  }

  .list-contracted {
    width: calc(100% - 380px);
  }

</style>
