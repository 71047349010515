<template>
  <div class="reportContainer" v-if="relevantReportTypes !== undefined">
    <transition name="fade">
      <div v-if="isOrderingReport || isShowingReceipt" class='dl_cover'>
        <div v-if="isOrderingReport">
          <icon  name='fa-cog fa-spin'/>
          <br><b>{{ x('pleaseWait') }}</b>
          <br>{{ x('orderingReportDots') }}
        </div>
        <div v-if="isShowingReceipt">
          <icon name="fa-check"/>
          <br>{{ x('reportOrdered') }}
        </div>
      </div>
    </transition>

    <div class="selectButtons">

      <button class="lsc-button lsc-rounded-10 ThemeButton" @click="selectAllVisits"><i
        class="fa fa-plus-circle"/>{{ x('selectAll') }}
      </button>
      <button class="lsc-button lsc-rounded-10 red" @click="deselectAllVisits"
              style="margin-left: 10px;"><i class="fa fa-minus-circle"/>{{ x('deselectAll') }}
      </button>

      <div class="lsc-clear"/>

    </div>

    <p v-if="aboveBatchThreshold" class="above-batch-threshold">
      {{ x('tooManyReportsChosen') }}{{ batchThreshold }}
    </p>

    <p v-if="selectedVisits.length === 0 && !isShowingReceipt" class='no_images_yet'>
      {{ x('noVisitsSelected') }}
    </p>

    <div class="dlImages"
         v-if="selectedVisits.length > 0 && !isShowingReceipt && !isOrderingReport">
      <span v-for="item of selectedVisits" class='img'
            :style="{background: 'url('+config.thumbBaseUrl + item.firstPictureName+')'}"
            :key="item.visitId">
        <a @click="deselectVisit(item.id)"
           class='Theme_C4_BG Theme_C4_TXT Theme_C4_Border'><i class='fa fa-close'/></a>
      </span>
      <p>&nbsp;</p>
      <p v-if="selectedVisits.length > 0" class="galleryCount">
        {{ x('reportsSelected')}}: {{ selectedVisits.length }}
      </p>
    </div>

    <div class="recipientsBox">

      <div v-if="relevantReportTypes.length >= 2">
        <h1 class="reportRecipientsHeadline">{{  x('chooseReportType') }}:</h1>
        <select class="lsc-input lsc-rounded select-report-type" v-model="selectedReportIndex">
          <option v-for="(reportType, index) in relevantReportTypes"
                  :key="reportType.id"
                  :value="index">
            {{ x(`rapportType.${reportType.id}`) }}
          </option>
        </select>
      </div>

      <h2 class="reportRecipientsHeadline">{{ x('recipients')}}</h2>
      <report-extra-recipients v-model="extraRecipients"
                               :visit-ids="selectedVisitIds"
                               :report-type-id="selectedReportType && selectedReportType.id"
      />
      <p v-if="reportRecipientsError" class="reportRecipientsError">
        {{ x('invalidEmails')}}
      </p>
      <textarea v-if="showExtraRecipientBox"
                v-model="emailRecipients"
                class="reportRecipients lsc-rounded" />
      <p class="checkboxLine" v-if="showIncludeExcel">
        <span class="checkbox"><checkbox v-model="includeExcel" checkbox-style="white" /> {{x('includeExcel')}}</span>
      </p>
      <p class="checkboxLine" v-if="showIncludeImages">
        <span class="checkbox"><checkbox v-model="includeImages" checkbox-style="white" /> {{x('includeImages')}}</span>
      </p>
    </div>

    <div class="command-buttons-container">

      <button id="orderReport" class='lsc-button lsc-rounded-10 ThemeButton right' @click="orderReport()"
              :disabled="allRecipients.length === 0 || selectedVisits.length === 0 || aboveBatchThreshold"><icon
        name='fa-download'/>{{ x('orderReport') }}
      </button>

      <button class="lsc-button lsc-rounded-10 Theme_C4_BG Theme_C4_TXT Theme_C4_Border right"
              @click="toggleReports()" style="margin-left: 10px;">{{ x('cancel')}}
      </button>

      <div class="lsc-clear"/>

    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { uniq } from 'lodash';

  import { httpPost } from '@/classes/httpHelper';
  import Checkbox from '@/components/Checkbox';
  import ReportExtraRecipients from '@/components/Pages/ReportExtraRecipients';
  import * as Email from '@/classes/email-helper';
  import ReportOptions from '@/enums/report-options';
  import EmailSources from '@/enums/email-sources';
  import translate from '../Mixins/Translate';

  /**
   * This shows a ui for picking visits and entering email addresses in Admin->Gallery
   */
  export default {
    name: 'gallery-report',
    components: { ReportExtraRecipients, Checkbox },
    mixins: [translate],
    data() {
      return {
        dlObject: {},
        isOrderingReport: false,
        isShowingReceipt: false,
        timerId: null,
        emailRecipients: '',
        extraRecipients: [],
        reportRecipientsError: false,
        includeExcel: false,
        includeImages: false,
        selectedReportIndex: 0,
      };
    },
    computed: {
      // How many visits may be selected for this report to be ordered
      batchThreshold() {
        if (!this.selectedReportType) return 0;
        return this.selectedReportType.reportMaxBatch;
      },
      selectedVisits() {
        return Object.values(this.selectedForReport);
      },
      selectedVisitIds() {
        return this.selectedVisits.map((v) => v.id);
      },
      aboveBatchThreshold() {
        return this.selectedVisits.length > this.batchThreshold;
      },
      selectedReportType() {
        return this.relevantReportTypes[this.selectedReportIndex];
      },
      jobOptions() {
        if (!this.selectedReportType) return [];
        return this.selectedReportType.jobOptions || [];
      },
      showIncludeExcel() {
        return this.jobOptions.includes(ReportOptions.includeExcel);
      },
      showIncludeImages() {
        return this.jobOptions.includes(ReportOptions.includeImages);
      },
      showExtraRecipientBox() {
        if (!this.selectedReportType) return false;
        return this.selectedReportType.emailOptions.includes(EmailSources.additional);
      },
      allRecipients() {
        let combinedRecipients = [...this.extraRecipients];

        const manualEmails = Email.cleanAndSplitEmailRecipients(this.emailRecipients);
        if (manualEmails.length > 0) {
          combinedRecipients = combinedRecipients.concat(manualEmails);
        }

        combinedRecipients = uniq(combinedRecipients);
        return combinedRecipients;
      },
      ...mapState(['config']),
      ...mapState('ReportStore', ['reportUIVisible', 'selectedForReport']),
      ...mapGetters('ReportStore', {
        relevantReportTypes: 'getReportTypesForGallery',
      }),
    },
    watch: {
      reportUIVisible(newValue) {
        if (newValue === false) {
          this.hideReportUI();
        }
      },
    },
    methods: {
      toggleReports() {
        this.$store.commit('ReportStore/toggleReportsVisible');
      },
      hideReportUI() {
        this.deselectAllVisits();
      },
      deselectVisit(visitId) {
        this.$store.commit('ReportStore/deselectVisitForReport', visitId);
      },
      selectAllVisits() {
        this.$store.dispatch('ReportStore/selectAllVisits');
      },
      deselectAllVisits() {
        this.$store.commit('ReportStore/deselectAllVisits');
      },
      async orderReport() {
        if (this.isOrderingReport) return;

        this.reportRecipientsError = !Email.validateEmails(this.emailRecipients);
        if (this.reportRecipientsError) return;

        const emailRecipients = Email.makeString(this.allRecipients);
        this.isOrderingReport = true;
        console.log('Email recipients: ', emailRecipients);

        try {
          const visitIds = this.selectedVisits.map((v) => v.id);
          const data = {
            emailRecipients,
            visitIds,
            reportType: this.selectedReportType.id,
          };
          // These options should only be included if they are relevant for the selected report type.
          if (this.showIncludeExcel) {
            data.includeExcel = this.includeExcel;
          }
          if (this.showIncludeImages) {
            data.includeImages = this.includeImages;
          }
          await httpPost('orderreport', data);
          this.isOrderingReport = false;
          this.isShowingReceipt = true;
          this.timerId = setTimeout(() => {
            this.clearUI();
            this.toggleReports();
          }, 3000);
        } catch (errorMessage) {
          console.log(errorMessage);
          const errMsg = errorMessage || 'Ukendt fejl';
          $('.dlContainer .dl_cover').html(
            `<i class='fa fa-exclamation-circle red'></i>
<br>
<b>Der opstod en fejl.</b><br>(${errMsg})`,
          );
          setTimeout(() => {
            $('.dlContainer .dl_cover').remove();
          }, 4000);
        }
      },
      clearUI() {
        // Enable to remove all selected pictures after download.
        this.deselectAllVisits();
        this.isOrderingReport = false;
        this.isShowingReceipt = false;
      },
    },
  };
</script>

<style lang="scss">
 .reportContainer {
    background: #f0f0f0;
    border: 1px solid #e5e5e5;
    padding: 10px;
    color: #939393;
    margin-bottom: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .no_images_yet {
      text-align: center;
      padding: 40px;
      flex-grow: 1;
    }

    .selectButtons {
      margin-bottom: 10px;
    }

    .dl_cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.85);
      z-index: 10;
      text-align: center;
      font-size: 12px;
      color: #808080;
      margin: -11px;

      i {
        padding: 0;
        font-size: 48px;
        color: #565656;
        margin: 30px 0 5px;
      }

      b {
        font-size: 16px;
      }
    }

    .dlImages {
      flex-grow: 1;
      overflow-y: scroll;
      height: 400px;

      a {
        display: inline-flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        border-bottom: 2px solid;
        text-decoration: none;
        float: right;
        margin: 3px 3px 0 0;
      }

      span.img {
        display: inline-block;
        width: 100px;
        height: 100px;
        margin: 10px 10px 0 0;
        background-size: cover !important;
        background-position: center center !important;
        border: 1px solid #a0a0a0;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 1);
      }
    }

    .receipt {
      text-align: center;
      padding: 40px;
      font-size: 28px;
      color: black;
    }

    .galleryCount {
      font-size: 12px;
    }

    .above-batch-threshold {
      font-weight: bold;
      text-align: center;
      color: red;
      padding: 10px;

    }

    .recipientsBox {
      border-top: 1px solid white;

      .select-report-type {
        width: 100%;
        margin-bottom: 10px;
      }

      .reportRecipients {
        width: 100%;
        border: 1px solid silver;
        padding: 10px;
        /*min-height: 60px;*/
      }

      h1, h2 {
        width: 100%;
        padding: 10px 10px 0 10px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        color: var(--ThemeTakePicH2Color);
      }

      .checkboxLine {
        margin: 0 0 5px 0;

        .checkbox {
          margin-right: 20px;
        }
      }
    }

    .reportRecipientsError {
      padding: 5px 10px 5px 10px;
      color: red;
    }

    .command-buttons-container {
      padding: 10px 0;

      button {
        margin-left: 10px;
      }
    }
  }

  /*noinspection CssUnusedSymbol*/
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  /*noinspection CssUnusedSymbol*/
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
