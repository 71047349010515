/**
* This component shows two buttons in the top of the gallery for displaying the UI for
* downloading images and for ordering reports.
*/
<template>
    <div>
      <button v-if="config.features[featureNames.downloads]"
              :disabled="downloadUIVisible || reportUIVisible"
              class="lsc-button lsc-rounded-10 Theme_C3_BG
              Theme_C3_TXT Theme_C3_Border toggle_button"
              @click="toggleDownload">
        {{ x('chooseForDownload') }}
      </button>

      <button v-if="config.features[featureNames.reports]"
              :disabled="downloadUIVisible || reportUIVisible"
              class="lsc-button lsc-rounded-10 Theme_C3_BG
              Theme_C3_TXT Theme_C3_Border toggle_button"
              @click="toggleReports">
        {{ x('selectForReport') }}
      </button>

      <div class="lsc-clear"></div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import translate from '@/components/Mixins/Translate';
  import FeatureNames from '@/enums/feature-names';
  import Views from '@/enums/views';
  import DisplayModes from '@/enums/display-modes';

  export default {
    name: 'report-and-download-buttons',
    mixins: [translate],
    data() {
      return {
        featureNames: FeatureNames,
      };
    },
    computed: {
      ...mapState(['config', 'displayModes']),
      ...mapState('DownloadStore', ['downloadUIVisible']),
      ...mapState('ReportStore', ['reportUIVisible']),
    },
    methods: {
      toggleDownload() {
        // NOTE: This should only happen when opening the download ui.
        // Currently, opening happens here, and closing happens in Download.vue
        // That is the reason that there is no check for downloadUIVisible.
        if (this.displayModes[Views.Gallery] === DisplayModes.Insta) {
          const obj = {};
          obj[Views.Gallery] = DisplayModes.SmallCards;
          this.$store.commit('setDisplayMode', obj);
        }
        this.$store.commit('DownloadStore/toggleDownloadVisible');
      },
      toggleReports() {
        this.$store.commit('ReportStore/toggleReportsVisible');
      },
    },
  };
</script>

<style scoped>
.toggle_button {
  margin-bottom: 10px;
  margin-right: 6px;
}
</style>
