<template>
  <div class="extra-recipients">
    <p class="checkboxLine" v-if="showCheckboxUserMail && user.email">
      <checkbox v-model="includeUserEmail" checkbox-style="white"/>
      {{x('yourself')}}: {{ user.email }}
    </p>
    <p class="checkboxLine" v-if="showCheckboxCustomerMail && chainEmails.length > 0">
      <checkbox v-model="includeChainEmails" checkbox-style="white"/>
      {{x('chainEmail')}}: {{ chainEmails.join(', ') }}
    </p>
    <p class="checkboxLine" v-if="showCheckboxCustomerMail && customerEmails.length > 0">
      <checkbox v-model="includeCustomerEmails" checkbox-style="white"/>
      {{x('customerEmail')}}: {{ customerEmails.join(', ') }}
    </p>
    <p class="checkboxLine" v-if="showCheckboxSurveyMail && reportEmails.length > 0">
      <checkbox v-model="includeReportEmails" checkbox-style="white"/>
      {{x('reportEmail')}}: {{ reportEmails.join(', ') }}
    </p>
    <p class="recipientsInstructions">{{ x('surveyRptGuideText')}}</p>
  </div>
</template>

<script>
  import { uniq } from 'lodash';
  import { mapState } from 'vuex';
  import ComponentEventNames from '@/enums/component-event-names';
  import Checkbox from '@/components/Checkbox';
  import { httpPost } from '@/classes/httpHelper';
  import * as Email from '@/classes/email-helper';
  import EmailSources from '@/enums/email-sources';
  import translate from '../Mixins/Translate';

  /**
   * This component will extract email recipients from an array of visits:
   * Customer email addresses
   * Chain email addresses
   *
   */
  export default {
    name: 'report-extra-recipients',
    props: {
      visitIds: {
        // An array of visits that this component will extract recipients from
        type: Array,
        required: true,
      },
      modelValue: {
        // The array of recipients that this component will work on
        type: Array,
        required: true,
      },
      // The kind of report we are about to order. This gives info about which fields to show
      // and what the max number of visits to include is.
      reportTypeId: {
        type: String,
      },
    },
    emits: [ComponentEventNames.updateModelValue],
    components: {
      Checkbox,
    },
    mixins: [translate],
    data() {
      return {
        includeUserEmail: true,
        includeChainEmails: false,
        includeCustomerEmails: false,
        includeReportEmails: true,
        chainEmails: [],
        customerEmails: [],
        reportEmails: [],
      };
    },
    computed: {
      showCheckboxUserMail() {
        return this.emailOptions.includes(EmailSources.user);
      },
      showCheckboxCustomerMail() {
        return this.emailOptions.includes(EmailSources.customer);
      },
      showCheckboxChainMail() {
        return this.emailOptions.includes(EmailSources.chain);
      },
      showCheckboxSurveyMail() {
        return this.emailOptions.includes(EmailSources.survey);
      },
      emailOptions() {
        if (!this.reportType) return [];
        return this.reportType.emailOptions;
      },
      reportType() {
        return this.availableReportTypes.find((rt) => rt.id === this.reportTypeId);
      },
      combinedRecipients() {
        let combinedRecipients = [];

        if (this.chainEmails.length > 0 && this.showCheckboxChainMail && this.includeChainEmails) {
          combinedRecipients = combinedRecipients.concat(this.chainEmails);
        }
        if (this.customerEmails.length > 0 && this.showCheckboxCustomerMail && this.includeCustomerEmails) {
          combinedRecipients = combinedRecipients.concat(this.customerEmails);
        }
        if (this.reportEmails.length > 0 && this.showCheckboxSurveyMail && this.includeReportEmails) {
          combinedRecipients = combinedRecipients.concat(this.reportEmails);
        }
        if (this.includeUserEmail && this.showCheckboxUserMail && this.user.email) {
          combinedRecipients.push(this.user.email);
        }

        combinedRecipients = Email.cleanEmailArray(combinedRecipients);
        combinedRecipients = uniq(combinedRecipients);
        return combinedRecipients;
      },
      ...mapState(['config', 'user']),
      ...mapState('ReportStore', ['availableReportTypes']),
    },
    watch: {
      visitIds: {
        immediate: true,
        handler() {
          this.loadRecipients();
        },
      },
      combinedRecipients(newValue) {
        this.$emit(ComponentEventNames.updateModelValue, newValue);
      },
    },
    methods: {
      async loadRecipients() {
        if (this.visitIds.length === 0) {
          this.chainEmails = [];
          this.customerEmails = [];
          this.reportEmails = [];
          return;
        }

        let result = await httpPost('chains/emails', { visitIds: this.visitIds });
        this.chainEmails = Email.cleanEmailArray(result.emails);

        result = await httpPost('customers/emails', { visitIds: this.visitIds });
        this.customerEmails = Email.cleanEmailArray(result.emails);

        result = await httpPost('surveys/emails', { visitIds: this.visitIds });
        this.reportEmails = Email.cleanEmailArray(result.emails);
      },
    },
  };
</script>

<style lang="scss">
  .extra-recipients {
    margin: 10px 0;
    font-size: 11px;

    .checkboxLine {
      vertical-align: center;
      line-height: 25px;
      margin: 10px 0;
    }
  }
</style>
