<template>
  <div class="dlContainer">
    <transition name="fade">
      <div v-if="isDownloading" class='dl_cover'>
        <icon name='fa-cog fa-spin'/>
        <br><b>{{ x('pleaseWait') }}</b>
        <br>{{ x('preparingPackageForDownload') }}
      </div>
    </transition>

    <div class="selectButtons">

      <button class="lsc-button lsc-rounded-10 ThemeButton" @click="selectAllVisits">
        <icon name="fa-plus-circle"/>
        {{ x('selectAll') }}
      </button>
      <button class="lsc-button lsc-rounded-10 red" @click="deselectAllVisits"
              style="margin-left: 10px;">
        <icon name="fa-minus-circle"/>
        {{ x('deselectAll') }}
      </button>

    </div>

    <p v-if="selectedImages.length === 0" class='no_images_yet'>{{ x('noPicturesSelected') }}</p>

    <div class="dlImages">
      <span v-for="item of selectedImages" class='img'
            :style="{background: 'url('+config.thumbBaseUrl + item.imageName+')'}"
            :key="item.imageName">
        <a @click="deselectVisit(item.visitId, item.imageName)"
           class='Theme_C4_BG Theme_C4_TXT Theme_C4_Border'><i class='fa fa-close'/></a>
      </span>
    </div>

    <div class="command-button-container">

      <p v-if="selectedImages.length > 0" class="galleryCount">{{ x('picturesSelected')}}: {{
          selectedImages.length }}</p>

      <button class='lsc-button lsc-rounded-10 ThemeButton right' @click="downloadVisits()">
        <icon name='fa-download'/>
        {{ x('downloadSelectedImages') }}
      </button>

      <button class="lsc-button lsc-rounded-10 right Theme_C4_BG Theme_C4_TXT Theme_C4_Border"
              @click="toggleDownload()"
              style="margin-left: 10px;">
        {{ x('cancel')}}
      </button>

      <div class="lsc-clear"/>

    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpPost } from '@/classes/httpHelper';
  import translate from '../Mixins/Translate';

  export default {
    name: 'download',
    mixins: [translate],
    data() {
      return {
        dlObject: {},
        isDownloading: false,
      };
    },
    computed: {
      selectedImages() {
        const sel = [];
        for (const visitId in this.selectedForDownload) {
          if (Object.prototype.hasOwnProperty.call(this.selectedForDownload, visitId)) {
            const imageNames = this.selectedForDownload[visitId];
            for (const imageName of imageNames) {
              sel.push({
                visitId,
                imageName,
              });
            }
          }
        }
        return sel;
      },
      ...mapState(['config']),
      ...mapState('DownloadStore', ['downloadUIVisible', 'selectedForDownload']),
    },
    methods: {
      toggleDownload() {
        this.$store.commit('DownloadStore/toggleDownloadVisible');
      },
      deselectVisit(visitId, imageName) {
        this.$store.commit('DownloadStore/deselectImageForDownload', { visitId, imageName });
      },
      selectAllVisits() {
        this.$store.dispatch('DownloadStore/selectAllImages');
      },
      deselectAllVisits() {
        this.$store.commit('DownloadStore/deselectAllImages');
      },
      async downloadVisits() {
        if (this.isDownloading) return;
        this.isDownloading = true;
        await this.$nextTick();

        let imageIds = [];
        for (const visitId in this.selectedForDownload) {
          if (Object.prototype.hasOwnProperty.call(this.selectedForDownload, visitId)) {
            imageIds = imageIds.concat(this.selectedForDownload[visitId]);
          }
        }

        try {
          const { downloadUrl } = await httpPost('download', { imageIds });
          this.downloadAndClearUI(downloadUrl);
        } catch (errorMessage) {
          console.log(errorMessage);
          const errMsg = errorMessage || 'Ukendt fejl';
          $('.dlContainer .dl_cover').html(`<i class='fa fa-exclamation-circle red'></i>
<br><b>Der opstod en fejl.</b><br>(${errMsg})`);
          setTimeout(() => {
            $('.dlContainer .dl_cover').remove();
            this.isDownloading = false;
          }, 4000);
        }
      },
      downloadAndClearUI(url) {
        console.log('url: ', url);
        const a = $('<a>').attr('href', url).attr('download', url).attr('target', 'blank')
          .appendTo('body');
        a[0].click();
        a.remove();

        // Enable to remove all selected pictures after download.
        // this.deselectAllVisits();
        this.isDownloading = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .dlContainer {
    background: #f0f0f0;
    border: 1px solid #e5e5e5;
    padding: 10px;
    color: #939393;
    margin-bottom: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .no_images_yet {
      flex-grow: 0;
      text-align: center;
      padding: 40px;
    }

    .selectButtons {
      margin-bottom: 10px;
    }

    .dl_cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.85);
      z-index: 10;
      text-align: center;
      font-size: 12px;
      color: #808080;
      margin: -11px;

      i {
        padding: 0;
        font-size: 48px;
        color: #565656;
        margin: 30px 0 5px;
      }

      b {
        font-size: 16px;
      }
    }

    .dlImages {
      flex-grow: 1;
      overflow-y: scroll;
      height: 400px;

      a {
        display: inline-flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        border-bottom: 2px solid;
        text-decoration: none;
        float: right;
        margin: 3px 3px 0 0;
      }

      span.img {
        display: inline-block;
        width: 100px;
        height: 100px;
        margin: 10px 10px 0 0;
        background-size: cover !important;
        background-position: center center !important;
        border: 1px solid #a0a0a0;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 1);
      }
    }

    .command-button-container {
      padding: 10px 0 0 0 ;
      flex-grow: 0;

      .galleryCount {
        font-size: 12px;
        margin-bottom: 5px;
      }

      button {
        margin-left: 10px;
      }
    }
  }

  /*noinspection CssUnusedSymbol*/
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  /*noinspection CssUnusedSymbol*/
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
