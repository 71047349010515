/**
 * These are the options available to reports. Different report types
 * might have different options available. All options should be in
 * this list, and any string in the Report_Types.jobOptions db column
 * should match a string value from this list.
 */
export default {
  includeExcel: 'includeExcel',
  includeImages: 'includeImages',
};
