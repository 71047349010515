<template>
  <label class="container">
    <input name="checkbox" type="checkbox" :checked="isChecked" @change="changed">
    <span class="checkmark" :class="{ white: checkboxStyle === 'white' }"></span>
  </label>
</template>

<script>
  import ComponentEventNames from '@/enums/component-event-names';

  export default {
    name: 'checkbox',
    props: ['modelValue', 'checkbox-style'],
    emits: [ComponentEventNames.updateModelValue],
    data() {
      return {
        isChecked: this.modelValue,
      };
    },
    watch: {
      modelValue(newValue) {
        this.isChecked = newValue;
      },
      isChecked(newValue) {
        console.log('isChecked changed.');
        this.$emit(ComponentEventNames.updateModelValue, newValue);
      },
    },
    methods: {
      changed(o) {
        console.log('changed', o);
        this.isChecked = o.target.checked;
      },
    },
  };
</script>

<style scoped lang="scss">

  /* Customize the label (the container) */
  .container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    /*font-size: 22px;*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .checkmark.white {
    background-color: white;
    border: 1px solid #eee;
    box-sizing: content-box;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a coloured background */
  .container input:checked ~ .checkmark {
    background-color: var(--ThemeBackgroundColor);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>
