/**
 * These are the options available to reports. Different report types
 * might have different options available. All options should be in
 * this list, and any string in the Report_Types.jobOptions db column
 * should match a string value from this list.
 */
export default {
  // Checkbox for the user's email
  user: 'user',

  // Checkbox for the customer's email
  customer: 'customer',

  // Checkbox for the chain's email
  chain: 'chain',

  // Checkbox for emails from email fields in the survey
  survey: 'survey',

  // Checkbox for enabling textbox for adding extra addresses
  additional: 'additional',
};
