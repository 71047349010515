<template>
  <div>

    <download v-if="config.features[featureNames.downloads]" v-show="downloadUIVisible" />
    <gallery-report v-if="config.features[featureNames.reports]" v-show="reportUIVisible" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Download from '@/components/Gallery/Download';
  import FeatureNames from '@/enums/feature-names';
  import GalleryReport from '@/components/Gallery/GalleryReport';

  export default {
    name: 'gallery-drawer',
    components: {
      Download,
      GalleryReport,
    },
    data() {
      return {
        featureNames: FeatureNames,
      };
    },
    computed: {
      ...mapState(['config', 'displayModes']),
      ...mapState('DownloadStore', ['downloadUIVisible']),
      ...mapState('ReportStore', ['reportUIVisible']),
    },
  };
</script>
